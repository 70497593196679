import React from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Home from './Home';
import ChangeEmail from './ChangeEmail';

const App = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/change-emailaddress' element={<ChangeEmail/>}/>
    </Routes>
    </BrowserRouter>
  )
}

export default App