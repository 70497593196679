import React,{useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const ChangeEmail = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('secret');


    useEffect(()=>{
        const verifyEmail=async()=>{
            await axios.post(`https://api.menehariya.com/v1/api/verify-email?secret=${id}`,{
            })
            .then((res)=>{console.log("res",res);
                window.location.href="https://menehariya.com/"
            })
            .catch((err)=>{console.log("err",err)})
        }
        verifyEmail();
    },[])
  return (
    <div>{id}</div>
  )
}

export default ChangeEmail