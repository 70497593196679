import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate=useNavigate();
    // const fetchConfig=getFetchConfig();
    useEffect(()=>{
        navigate('/change-emailaddress');
    },[])
  return (
    <div></div>
  )
}

export default Home